import { Box, Carousel, useWindowProperties } from '@lojinha/design-system'
import React from 'react'
import styled from 'styled-components'
import { normalizeHtmlId, sanitizeImage } from '@lojinha/helpers'
import { t } from '../../dictionary'
import { LastCard } from '../last-card'
import { ProductCardDish, ProductCardIndividual } from '../product-card'
import { Header } from './header'
import { CarouselDisplayProps } from './types'
import {
  MarketableKind,
  IcmsEnumComponentcontainerdisplayCardsize as CardSize,
} from '../../../../../packages/palantir/src/palantirTypes'
import { useLojinhaContext } from '@lojinha/context'

const CustomBox = styled(Box)`
  > a {
    text-decoration: none !important;
  }
`

export const CarouselItem = ({
  id,
  kind,
  name,
  slug,
  cardSize,
  items,
  originList,
  onArrowSlideClick,
  onRenderItem,
  onItemClick,
  onSeeAllClick,
  showLastCard,
}: CarouselDisplayProps) => {
  const { isMobile, isTablet } = useWindowProperties()
  const { user } = useLojinhaContext()

  let finalSize = cardSize || CardSize.Small
  let cardImageSize: 'small' | 'medium' | 'big' = 'medium'
  let slideCountToShow = 2
  let heights: string[] = []

  if (
    cardSize === CardSize.Large ||
    (cardSize === CardSize.Default && kind === MarketableKind.Meal)
  ) {
    finalSize = CardSize.Large
  } else {
    finalSize = CardSize.Small
  }

  if (finalSize === CardSize.Large) {
    cardImageSize = 'big'
    slideCountToShow = isMobile ? 1.2 : 2
    heights = ['352px', '536px']
  } else {
    cardImageSize = 'medium'
    slideCountToShow = isMobile ? 2 : isTablet ? 3 : 4
    heights = ['312px', '408px']
  }

  const CardComponent =
    finalSize === CardSize.Large ? ProductCardDish : ProductCardIndividual

  return (
    <Carousel
      slideToShow={slideCountToShow}
      id={normalizeHtmlId(name)}
      constrolsOnVertical={isMobile ? 'bottom' : undefined}
      constrolsOnHorizontal={isMobile ? 'center' : undefined}
      onNextSlideClick={() => onArrowSlideClick({ name, direction: 'right' })}
      onPrevSlideClick={() => onArrowSlideClick({ name, direction: 'left' })}
      header={
        <Header
          name={name}
          slug={slug}
          onLinkClick={() =>
            onSeeAllClick && onSeeAllClick({ name, isHeader: true })
          }
        />
      }
    >
      {items.map((item, index) => {
        return (
          <Box key={item.id} height={heights}>
            <CardComponent
              id={item.id}
              displayName={name}
              kind={item.kind}
              classification={item.classification}
              title={item.mainProduct ?? item.name}
              description={item.subtitle || item.sideProducts?.join(' + ')}
              badges={item.itemTags.nodes}
              isAvailable={item.isAvailable}
              isAgeRestricted={item.isAgeRestricted}
              imageUrl={sanitizeImage({
                imageUrl: item.imageUrl,
                size: cardImageSize,
              })}
              price={item.price || 0}
              priceFrom={item.priceFrom || 0}
              weight={t.weightAndUnit(item.weight, item.unit)}
              quantity={0}
              imageAriaLabel={item.name}
              sku={item.sku}
              eventList={originList}
              positionInList={index}
              onRenderItem={() =>
                onRenderItem &&
                onRenderItem({
                  id: item.id,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  listName: originList,
                  isAvailable: item.isAvailable,
                })
              }
              onItemClick={() =>
                onItemClick &&
                onItemClick({
                  id: item.id,
                  sku: item.sku,
                  name: item.name,
                  positionInList: index,
                  price: item.price ?? 0,
                  originList: originList,
                  isAvailable: item.isAvailable,
                  user,
                })
              }
              slug={item.seo.url}
            />
          </Box>
        )
      })}
      {showLastCard && onSeeAllClick && (
        <CustomBox key={`last-card-${id}`} height={heights}>
          <LastCard
            slug={slug}
            onCardClick={() => {
              onSeeAllClick({ name })
            }}
            text={t.lastCardText(name)}
          />
        </CustomBox>
      )}
    </Carousel>
  )
}
